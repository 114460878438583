import Link from 'next/link';
import {ChevronRightIcon} from '@heroicons/react/solid';
import {mainRoutes} from '../lib/routes';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import AMPTechLogoSvg from '../components/svg/amptechLogoSvg';
import React from 'react';
import ContinueShopping from '../components/ContinueShopping';

export default function Custom404() {

  const router = useRouter();
  const {t: translateMenu} = useTranslation('menu');
  const {t: translateCommon} = useTranslation('common');

  return (
      <div className="bg-white">
        <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 pt-16">
            <div className={'w-72 mx-auto'}>
              <AMPTechLogoSvg light={false}/>
            </div>
          </div>
          <div className="max-w-xl mx-auto py-16 sm:py-24">
            <div className="text-center">
              <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404
                error</p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 sm:text-5xl">
                {translateCommon('PAGE_NOT_EXIST')}
              </h1>
              <p className="mt-2 text-lg text-gray-500"> {translateCommon(
                  'PAGE_COULD_NOT_BE_FOUND')}
              </p>
            </div>
            <ContinueShopping/>
          </div>
        </main>
      </div>
  );
}

export async function getStaticProps({locale}) {

  return {
    props: {
      ...(await serverSideTranslations(locale,
          ['common', 'site-meta', 'menu', 'order', 'pages'])),
    },
  };
}
