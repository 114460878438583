import {mainRoutes} from '../lib/routes';
import Link from 'next/link';
import {ChevronRightIcon} from '@heroicons/react/solid';
import React from 'react';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

const ContinueShopping = ({}) => {

  const router = useRouter();
  const {t: translateMenu} = useTranslation('menu');
  const {t: translateCommon} = useTranslation('common');

  return <div className="mt-12">
    <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">{translateCommon(
        'CONTINUE_SHOPPING')}</h2>
    <ul role="list"
        className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
      {mainRoutes(translateMenu, router).map((link, linkIdx) => (
          <li key={linkIdx}
              className="relative py-6 flex items-start space-x-4">
            <div className="flex-shrink-0">
            </div>
            <div className="min-w-0 flex-1">
              <h3 className="text-base font-medium text-gray-900">
                      <span
                          className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">

                       <Link href={link.uri}>
                        <a href={link.uri}
                           className="focus:outline-none uppercase">
                          <span className="absolute inset-0"
                                aria-hidden="true"/>
                          {link.title}
                        </a>
                         </Link>
                      </span>
              </h3>
            </div>
            <div className="flex-shrink-0 self-center">
              <ChevronRightIcon className="h-5 w-5 text-gray-400"
                                aria-hidden="true"/>
            </div>
          </li>
      ))}
    </ul>

  </div>;
};

export default ContinueShopping;
